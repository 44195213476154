
/* eslint-disable @typescript-eslint/camelcase */
import Vue from "vue"
import { clearContext } from '@/store/localStorage'

export default Vue.extend({
	mounted() {
		this.logout()
	},
	methods: {
		async logout(): Promise<void> {
			/*
			This will not work due to custom "AppUrl" setting for element in appSettings.json for identityserver apparently will override this post_logout-redirect-uri.
			const endSessionArgs: { id_token_hint: string, post_logout_redirect_uri: string } = {
				id_token_hint: this.$vStore.getters["oidcStore/oidcIdToken"],
				post_logout_redirect_uri: this.$config ? this.$config.logoutlocation : ""
			}
			this.$vStore.dispatch("oidcStore/signOutOidc", endSessionArgs)
			*/

			/* Utilize an iframe to sign out, practically allowing us to redirect to ourselves instead of Element (AppUrl) */
			await this.$vStore.dispatch("oidcStore/signOutOidcSilent")
			clearContext()
			// eslint-disable-next-line
			this.$router.push({ path: "/" }).catch(() => {})

		},
	},
})
