import { Domain } from "@/types/responses";
import Vue from "vue";

export function setItem(
    key: string, 
    value: string): void 
{
    localStorage.setItem(key, value);
}

export function getItem(key: string): string 
{
    const cookie = localStorage.getItem(key);
    return cookie || "";
}

export function clearContext(): void {
    setItem("selectedCustomer", "");
    setItem("domainSize", "");
    setItem("domains", "");
}